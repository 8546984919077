import React from "react";
import Newsletter from "./Newsletter";
import Image from "next/image";
import FooterColumn from "./FooterColumn";
import { useRouter } from "next/router";
import { classNames } from "../../utils";
import Logo from "../Logo";

const items = {
  account: {
    header: "Moje konto",
    items: [
      { name: "Zarejestruj się", href: "/customer/register" },
      { name: "Moje zamówienia", href: "/customer" },
      { name: "Historia transakcji", href: "/customer" },
    ],
  },
  information: {
    header: "Informacje",
    items: [
      { name: "Regulamin sklepu", href: "/regulamin_sklepu" },
      { name: "Regulaminy promocji", href: "/regulaminy_promocji" },
      { name: "Polityka prywatności", href: "/polityka_prywatnosci" },
      { name: "Jak mierzyć", href: "/jak_mierzyc" },
    ],
  },
  order: {
    header: "Zamówienie",
    items: [
      {
        name: "Dostawa i koszty przesyłki",
        href: "/dostawa_i_koszty_przesylki",
      },
      { name: "Zwroty i wymiana", href: "/zwroty_i_wymiana" },
      { name: "Reklamacje", href: "/reklamacje" },
    ],
  },
  aboutUs: {
    header: "O nas",
    items: [
      { name: "O nas", href: "/onas" },
      { name: "Kontakt", href: "/kontakt" },
      { name: "Blog", href: "/blog" },
      { name: "Relacje inwestorskie", href: "/gunstuxedos-sa" },
      { name: "Salony stacjonarne", href: "/salony" },
    ],
  },
};

const Footer: React.FC = () => {
  const router = useRouter();
  //  const green = router.asPath === "/";
  const green = false;
  return router.asPath !== "/order"
    ? (
      <div className="bg-black relative z-20">
        <div className="container mx-auto">
          <div className="text-center py-4 lg:py-12">
            <div className="mx-auto hidden lg:flex justify-center">
              <Logo
                className={green
                  ? "w-24 h-24 text-green-500"
                  : "w-24 h-24 text-white"}
              />
            </div>
            <div className="mx-auto flex lg:hidden justify-center">
              <Logo
                className={green
                  ? "w-16 h-16 text-green-500"
                  : "w-16 h-16 text-white"}
              />
            </div>
          </div>
          <div className="pb-8 px-4 lg:px-8">
            <Newsletter green={green} />
          </div>
          <div>
            <div className="lg:grid lg:grid-cols-4 md:gap-8 border-t lg:border-t-0 border-gray-800 lg:space-y-0 lg:py-8 divide-y divide-gray-800 lg:divide-y-0">
              {Object.values(items).map((menuItem) => (
                <FooterColumn
                  green={green}
                  title={menuItem.header}
                  key={menuItem.header}
                >
                  <ul role="list" className="space-y-1 font-thin">
                    {menuItem.items.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            "text-base",
                            green
                              ? "text-green-600 hover:text-green-500"
                              : "text-gray-300 hover:text-white",
                          )}
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </FooterColumn>
              ))}
            </div>
          </div>
        </div>
        <div className="p-8 py-4 md:flex flex justify-center text-xs bg-black border-t border-gray-800">
          <a href="https://socommerce.pl">
            <Image
              alt="Socommerce"
              src="https://socommerce.b-cdn.net/logo_white.svg"
              width="110"
              height="30"
            />
          </a>
        </div>
      </div>
    )
    : null;
};
export default Footer;
