import React from "react";
import {
  Collection as ICollection,
  CollectionProduct,
  Link as ILink,
} from "@wk93/socommerce-sdk";
import Breadcrumbs from "../../layout/utils/Breadcrumbs";
import Price from "../../layout/utils/Price";
import Container from "../../layout/utils/Container";
import PageTitle from "../../layout/utils/PageTitle";
import Gallery from "../../utils/Gallery";
import Image from "next/legacy/image";
import ImageBlock from "../../gnt/ImageBlock";
import Button from "../../utils/Button";
import { classNames } from "../../utils";
import Filters from "../../utils/Filters";
import Pagination from "./Pagination";
import Link from "next/link";
interface Props {
  collection: ICollection;
  link?: ILink;
  search?: boolean;
  querySearch?: string;
  onSearchMore?: () => void;
}

const Collection: React.FC<Props> = ({
  collection,
  link,
  search = false,
  querySearch,
  onSearchMore = () => {},
}) => {
  if (!search && collection.products.length === 0) {
    return (
      <>
        <div className="relative">
          <Image
            src={collection.media.length > 0
              ? collection.media[0]
              : "/gnt/cat-bg.jpg"}
            layout="fill"
            objectPosition="center"
            objectFit="cover"
          />
          <div className="relative z-10 bg-black bg-opacity-50">
            <div className="pb-10">
              {link
                ? (
                  <Breadcrumbs
                    white
                    items={[
                      ...collection.breadcrumbs,
                      { name: collection.name, link: link },
                    ]}
                  />
                )
                : null}
              <PageTitle
                white
                title={collection.name}
                description={collection.description
                  .replace(/<\/?[^>]+(>|$)/g, " ")
                  .trim()}
              >
              </PageTitle>
            </div>
          </div>
        </div>
        <div className="flex h-96 items-center justify-center text-center">
          <div>
            <div className="text-2xl">Brak produktów</div>
            <div>
              W tej karegorii nie ma jeszcze produktów. Zapraszamy wkrótce!
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {!search
        ? (
          <>
            <div className="relative">
              <Image
                src={collection.media.length > 0
                  ? collection.media[0]
                  : "/gnt/cat-bg.jpg"}
                layout="fill"
                objectPosition="center"
                objectFit="cover"
              />
              <div className="relative z-10 bg-black bg-opacity-50">
                <div className="pb-10">
                  {link
                    ? (
                      <Breadcrumbs
                        white
                        items={[
                          ...collection.breadcrumbs,
                          { name: collection.name, link: link },
                        ]}
                      />
                    )
                    : null}
                  <PageTitle
                    white
                    title={collection.name}
                    description={collection.description
                      .replace(/<\/?[^>]+(>|$)/g, " ")
                      .trim()}
                  >
                  </PageTitle>
                </div>
              </div>
            </div>
            <Filters
              count={collection.count}
              filters={collection.filters.sort((a, b) =>
                a.group.name.localeCompare(b.group.name)
              )}
            />
          </>
        )
        : null}

      <Container>
        <div className="grid grid-cols-2 gap-y-4 gap-x-4 py-8 sm:grid-cols-2 lg:grid-cols-4 lg:gap-y-8 xl:gap-x-8">
          {(collection.products.length > 20 &&
              link.slug === "kolekcja" &&
              !search
            ? [
              ...collection.products.slice(0, 8),
              { code: "test" } as CollectionProduct,
              ...collection.products.slice(8, 16),
              { code: "test" } as CollectionProduct,
              ...collection.products.slice(16),
            ]
            : collection.products).map((product, index) => (
              (
                <Link
                  key={`${product.code}-${index}`}
                  href={product.link && product.link.slug
                    ? `/${product.link.slug}`
                    : "/"}
                  onClick={() => onSearchMore()}
                  className="group relative"
                >
                  {product.tags.filter(
                          (tag) => tag.code === "SALE-sale",
                        ).length > 0 && product.discountPrice
                    ? (
                      <span className="absolute top-1 right-1 z-10 bg-red-600 px-2 py-1 text-xs text-white md:top-4 md:right-4">
                        - {Math.round(
                          ((product.price.brutto -
                            product.discountPrice.brutto) /
                            product.price.brutto) * 100,
                        )}
                        %
                      </span>
                    )
                    : product.tags.filter((tag) => tag.code === "new-new")
                        .length > 0
                    ? (
                      <span className="absolute top-1 right-1 z-10 bg-green-600 px-2 py-1 text-xs text-white md:top-4 md:right-4">
                        NOWOŚĆ
                      </span>
                    )
                    : null}
                  <div className="relative">
                    {product.stockUnits.reduce(
                        (prev, curr) => prev + curr.quantity,
                        0,
                      ) === 0
                      ? (
                        <div className="absolute bg-white inset-0 z-10 bg-opacity-40 flex items-center justify-center backdrop-blur">
                          <span className="text-red-500 text-4xl uppercase px-4 py-2 font-bold text-center">
                            SOLD OUT
                            <span className="block text-black text-xl">
                              Doszywamy :)
                            </span>
                          </span>
                        </div>
                      )
                      : null}
                    <div className="block lg:hidden">
                      <Gallery arrows={false} dots={true}>
                        {product.media.map((image) => (
                          <div
                            className={classNames(search ? "" : "bg-gray-100")}
                            key={image}
                          >
                            <div
                              className={classNames(
                                "relative mx-auto max-w-3xl",
                                product.tags.filter(
                                    (tag) => tag.code === "SALE-sale",
                                  ).length > 0
                                  ? "border border-red-500"
                                  : product.tags.filter((tag) =>
                                      tag.code === "new-new"
                                    ).length > 0
                                  ? "border border-green-500"
                                  : "",
                              )}
                            >
                              <ImageBlock
                                alt={product.name}
                                src={image}
                                width={1408}
                                height={1877}
                              />
                            </div>
                          </div>
                        ))}
                      </Gallery>
                    </div>
                    <div className="hidden lg:block">
                      <Gallery>
                        {product.media.map((image) => (
                          <div
                            className={classNames(search ? "" : "bg-gray-100")}
                            key={image}
                          >
                            <div
                              className={classNames(
                                "relative mx-auto max-w-3xl",
                                product.tags.filter(
                                    (tag) => tag.code === "SALE-sale",
                                  ).length > 0
                                  ? "border-2 border-red-500"
                                  : product.tags.filter((tag) =>
                                      tag.code === "new-new"
                                    ).length > 0
                                  ? "border-2 border-green-500"
                                  : "",
                              )}
                            >
                              <ImageBlock
                                src={image}
                                width={1408}
                                height={1877}
                              />
                            </div>
                          </div>
                        ))}
                      </Gallery>
                    </div>
                  </div>
                  <h3
                    className={classNames(
                      "mt-2 lg:mt-4 lg:text-sm",
                      search ? "text-gray-200" : "text-gray-700",
                    )}
                  >
                    {product.name}
                  </h3>
                  <div className="flex space-x-2">
                    {product.discountPrice
                      ? (
                        <p
                          className={classNames(
                            "mt-1 text-sm font-medium text-red-600 lg:text-lg",
                          )}
                        >
                          <Price value={product.discountPrice} />
                        </p>
                      )
                      : null}
                    <p
                      className={classNames(
                        "mt-1 text-sm font-medium lg:text-lg",
                        product.discountPrice
                          ? "text-gray-400 line-through"
                          : search
                          ? "text-white"
                          : "text-gray-900",
                      )}
                    >
                      <Price value={product.price} />
                    </p>
                  </div>
                </Link>
              )
            ))}
        </div>
        <div className="py-4 text-center">
          {!search
            ? <Pagination totalPages={Math.ceil(collection.count / 24)} />
            : collection.count > 24
            ? (
              (
                <Link
                  href={`/search/${querySearch}/page:2`}
                  onClick={onSearchMore}
                >
                  <Button size="normal" inverse>
                    Zobacz więcej
                  </Button>
                </Link>
              )
            )
            : null}
        </div>
      </Container>
    </>
  );
};
export default Collection;
